import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import { OrderDetailType } from "@Types/OrderType"
import useTransitOrder from "@Pages/DetailOrderScreens/Transit/UseTransitOrder"
import TransitAction from "@Components/OrderDetail/TransitProductOrder/TransitAction"
import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { Suspense, useContext, useEffect } from "react"
import { AuthContext } from "@Context/context/AuthContext"
import { clients } from "@Webapp-Clients/Clients"
import { useForm } from "react-hook-form"
import { FilesFormType } from "@Types/FilesFormType"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaFileUpladerForm } from "@Config/ShemaForms"
import AlertStyled from "@Theme/common/Alert.styled"
import { IconAttachment } from "@Theme/common/Icon.styled"
import { ErrorFieldForm } from "@Theme/common/Paragraph.styled"
import useHideActionButtons from "@Hooks/UseHideActionButtons"

const TransitScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const {hideTransitActions, updateVisibilityButtonActions} = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker

  const FileUpload = client["FileUpload" as keyof typeof client]
  const NoExistTransit = client["NoExistTransit" as keyof typeof client]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FilesFormType>({
    resolver: !!FileUpload ? yupResolver(shemaFileUpladerForm) : undefined,
  })

  const { submitDeliveredOrder, loadingTransit } = useTransitOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
  })

  return (
    <>
      {NoExistTransit && (
        <Suspense fallback={null}>
          <NoExistTransit
            shippingGroupId={shippingGroupId}
            courierDeliveryInfo={shippinGroupDetail?.custom?.courierDeliveryInfo}
            shippinGroupDetail={shippinGroupDetail}
          />
        </Suspense>
      )}
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos en tránsito" : undefined}
          />
          {!finishedShippingGroupAction && (
            <>
              <form onSubmit={handleSubmit(submitDeliveredOrder)}>
                {FileUpload && (
                  <Suspense fallback={null}>
                    <section className="padding-content ">
                      <AlertStyled variant="info" className="m-top-button1">
                        <IconAttachment />
                        <p>Adjuntar máximo 2 archivos</p>
                      </AlertStyled>
                      <FileUpload
                        id="file"
                        {...register("filesShippinGroup")}
                        multiple
                        accept="image/*"
                      />
                    </section>
                  </Suspense>
                )}

                {errors.filesShippinGroup && (
                  <ErrorFieldForm>{errors.filesShippinGroup.message}</ErrorFieldForm>
                )}
                {!hideTransitActions && (
                  <TransitAction loadingTransit={loadingTransit} />
                )}
              </form>
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(TransitScreen)
