import axios, { AxiosInstance } from "axios"
import { TypeVariation3, TypeVariation4 } from "@Types/CommonsTypes"
import { IProcessEnv } from "@Interfaces/IProcessEnv"
import { IAuth } from "@Interfaces/IUser"
import { GROUP_SERVICE_SERIES, WEB_APP_USER, WEB_SITES } from "@Helpers/constHelper"

export default class AxiosClient {
  private id: number
  private type: string
  private client: AxiosInstance
  private _href: string
  private websites: Array<string>
  private environments: Array<string>
  private _process: IProcessEnv
  private clients: Array<string>

  constructor(type: string) {
    this.type = type
    this._href = window.location.href
    this.websites = WEB_SITES
    this.environments = ["dev", "uat", "prod"]
    this.clients = ["OMA", "OIM", "OOM", "OLM"]
    this._process = process.env
    this.client = this.createAxiosInstance(this.type)
    this.id = new Date().getTime()

    this.seInterceptorRequest()
  }

  setTokenAuth(token: TypeVariation4): void {
    if (token) {
      this.client.defaults.headers.common["Authorization"] = token
    } else {
      delete this.client.defaults.headers.common["Authorization"]
    }
  }

  private seInterceptorRequest() {
    this.client.interceptors.request.use(
      (config) => {
        if (config.headers.common["Authorization"]) return config
        const userAuth: string = localStorage.getItem(WEB_APP_USER) || "{}"
        if (userAuth.match(/user/)) {
          const { user }: IAuth = JSON.parse(userAuth)

          if (user?.token) config.headers.common["Authorization"] = user.token
          else delete config.headers.common["Authorization"]
        }

        return config
      },
      (error) => {
        return Promise.reject(error)
      },
    )
  }

  getClient(): AxiosInstance {
    return this.client
  }

  setManuallyKey(arrKeys: Array<string>, manualKey: string): TypeVariation3 {
    if (manualKey.length && this._href.includes(this.websites[0])) {
      if (arrKeys.indexOf(manualKey) === -1) {
        throw new Error("Website or environment doesn't exist!")
        // return
      }
      return manualKey.toUpperCase()
    }
    return false
  }

  getCurrentWebsite(manualWebsite: string = "") {
    let currentWebsite: string = this.websites[1].toUpperCase()
    let canManually: TypeVariation3 = this.setManuallyKey(this.websites, manualWebsite)

    if (!!canManually) {
      return canManually
    }

    if (!this._href.includes(this.websites[0]) && !this._href.includes(this.websites[1])) {
      this.websites.forEach((website) => {
        if (this._href.includes(website)) {
          currentWebsite = website.toUpperCase()
          return
        }
      })
    }

    return currentWebsite
  }

  getCurrentEnv(manualEnv: string = ""): string {
    const isLocal: boolean = this._href.includes("localhost")
    const isDev: boolean = this._href.includes(this.environments[0])
    const isUat: boolean = this._href.includes(this.environments[1])
    let canManually: TypeVariation3 = this.setManuallyKey(this.environments, manualEnv)

    if (!!canManually) {
      return canManually
    }

    if (isDev || isLocal) {
      return this.environments[0].toUpperCase()
    } else if (isUat) {
      return this.environments[1].toUpperCase()
    } else {
      return this.environments[2].toUpperCase()
    }
  }

  buildUrl(group: string): string {
    const env: string = this.getCurrentEnv()
    const currentWebsite: string = this.getCurrentWebsite()
    console.log(env, currentWebsite, group)

    if (group === GROUP_SERVICE_SERIES) {
      console.log("group service listener", GROUP_SERVICE_SERIES)
      const url: TypeVariation4 = this._process[`REACT_APP_${env}_URL_LISTENER`.trim()]
      return `${url}`
    }

    const urlBase: TypeVariation4 = this._process[`REACT_APP_BASE_${env}_URL_${currentWebsite}`]
    const urlGroup: TypeVariation4 = this._process[`REACT_APP_API_URL_${group}`]
    return `${urlBase}-${urlGroup}`
  }

  createAxiosInstance(group: string): AxiosInstance {
    
    return axios.create({
      baseURL: this.buildUrl(group),
    })
  }
  getId(): number {
    return this.id
  }
}
