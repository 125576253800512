import { memo, useEffect, useState } from "react"
import Input from "@Components/Commons/Input"
import { useForm } from "react-hook-form"
import { OptionFilterWrapper, SearchFormStyled } from "@Components/SearchForm/SearchForm.styled"
import { ButtonStyled } from "@Theme/common/Button.styled"
import { ISearch, ISearchForm } from "@Interfaces/ISearchForm"
import { IconSearch } from "@Theme/common/Icon.styled"
import { yupResolver } from "@hookform/resolvers/yup"
import { shemaSearchForm } from "@Config/ShemaForms"
import RadioButton from "@Components/Commons/RadioButton"
import { OptionsFilterEnum } from "@Enums/OptionsFilterEnum"
import { filterOrderData } from "@Theme/assets/Data/FiltersData"

const SearchForm = memo(({ onClickSearch, search, optionFilter }: ISearch) => {
  const [filter, setFilterValue] = useState(OptionsFilterEnum.query.toString())
  const { register, handleSubmit, setValue } = useForm<ISearchForm>({
    resolver: yupResolver(shemaSearchForm),
  })

  useEffect(() => {
    if (search) setValue("search", search)
    if (optionFilter) setFilterValue(optionFilter)
  }, [search, setValue, optionFilter])

  const handleClickSubmit = ({ search }: ISearchForm) => {
    onClickSearch({ query: search, optionFilter: filter })
  }

  const handleChangeOptionFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilterValue(ev.target.value)
  }

  const resultPlaceHolder = filterOrderData.find(({ value }) => value === filter)?.label

  return (
    <>
      <OptionFilterWrapper>
        {filterOrderData.map(({ value, label }) => (
          <RadioButton
            key={value}
            name="optionFilter"
            value={value}
            checked={value === filter}
            onChange={handleChangeOptionFilter}
          >
            {label}
          </RadioButton>
        ))}
      </OptionFilterWrapper>
      <SearchFormStyled onSubmit={handleSubmit(handleClickSubmit)}>
        <ButtonStyled variant="primary">
          <span>BUSCAR</span>
          <IconSearch />
        </ButtonStyled>
        <Input type="search" placeHolder={resultPlaceHolder} {...register("search")} />
      </SearchFormStyled>
    </>
  )
})

export default SearchForm
