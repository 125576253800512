import styled, { css } from "styled-components"
import { VarianrActionGeneric } from "@Types/CommonsTypes"
import { displays } from "@Theme/base/Variables"
import { BottomToTop } from "@Theme/base/Animations"
import { respondTo } from "@Theme/base/Mixins"

export const NotificationListStyled = styled.div`
  position: fixed;
  left: 10px;
  top: 20px;
  right: 10px;
  z-index: 9999999;
  margin: auto;

  ${respondTo.sm`
     right: 5px;
     left: unset;
  `}
`
export const NotificationStyled = styled.div<{ variant: VarianrActionGeneric }>`
  display: grid;
  grid-template-columns: 35px 1fr;
  gap: 0.5rem;
  position: relative;
  box-sizing: border-box;
  min-height: 100px;
  background: ${({ theme }) => theme.palette.grey85};
  color: ${({ theme }) => theme.palette.white};
  border-radius: 8px;
  margin-bottom: 1rem;
  animation: ${BottomToTop} 1s alternate;

  & .content-type-icon {
    ${displays.flexColumn};
    align-self: center;
    height: 100%;
    border-radius: 8px 0 0 8px;
    ${({ variant }) => setTypeNotification(variant)}

    & > svg {
      font-size: 2rem;
    }
  }

  & .body-notification {
    & > p {
      &.title {
        font-size: 1.2rem;
        font-weight: 900;
        margin-top: 0.5rem;
      }
      &.message {
        font-size: 1rem;
        color: ${({ theme }) => theme.palette.grey10};
        margin-top: 0.2rem;
      }
    }
  }

  & .close-notification {
    position: absolute;
    right: 10px;
    top: 5px;
    cursor: pointer;
  }

  ${respondTo.sm`
    width: 380px;
  `}
`

const setTypeNotification = (variant: VarianrActionGeneric) => {
  switch (variant) {
    case "success":
      return css`
        background: ${({ theme }) => theme.alert.success.backgroundColor};
        color: ${({ theme }) => theme.palette.white};
      `
    case "danger":
      return css`
        background: ${({ theme }) => theme.alert.danger.backgroundColor};
        color: ${({ theme }) => theme.palette.white};
      `

    case "info":
      return css`
        background: ${({ theme }) => theme.alert.info.backgroundColor};
        color: ${({ theme }) => theme.palette.grey90};
      `
  }
}
