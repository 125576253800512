import { createGlobalStyle, css } from "styled-components"
import { respondTo } from "./Mixins"
import { displays } from "./Variables"

const GlobalStyled = createGlobalStyle`

  html {
    ${({ theme }) => {
      return css`
        font-size: ${theme.fontSizes.mainSize};
        ${respondTo.sm`
          font-size: ${theme.fontSizes.secondsize};
      `}
      `
    }}
  }

  body {
    margin: 0;
 
      background: linear-gradient(180deg, #ffffff 0%, #f4f5f8 100%);

      ${respondTo.sm`
         background: linear-gradient(250deg, #ffffff 0%, #F4F5F8 100%);
      `}
    
   
  }
  *:focus {
    outline: none;
  }
    button:focus {
    outline: none;
  }
  div::-webkit-scrollbar {
    display: none;
  }
  div {
    scrollbar-width: none;
  }
    h1 {
    font-size: 40px;
    font-weight: bold;
  }
  h2 {
    font-size: 30px;

  }
  h3 {
    font-size: 20px;

  }
  h4 {
    font-size: 18px;
  }
  
.relevant-title {
  font-weight: bold;
}

.padding-content {
  padding:1rem;
}

.m-top1 {
  margin-top: .5rem;
}

.m-top-button1{
    margin: 1rem 0
}
.m-lef-right{
    margin: 0 1rem;
}
.content-center{
    left: 50%;
    right: 50%;
    position: fixed;
    z-index: 2000;
    ${displays.flexCenter};
}

    & .field {
      ${displays.flexBase};
      gap: 0.5rem;
      
    }

    & .field-complex {
      padding-left: 0.5rem
    }

.ellipsis-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
`

export default GlobalStyled
