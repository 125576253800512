import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "@Context/context/AuthContext"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import { OrderDetailType } from "@Types/OrderType"
import { clients } from "@Webapp-Clients/Clients"
import { Suspense, useContext } from "react"

const DeliveredScreen = ({ shippinGroupDetail, shippingGroupId, order }: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]
  const ContentFileMedia = client["ContentFileMedia" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker

  const files = shippinGroupDetail?.status
    .filter(({ media }) => media)
    .flatMap(({ media }) => media?.ref.map(({ id }) => id))

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={"Productos entregados"}
          />
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}

          {ContentFileMedia && files && files.length > 0 && (
            <Suspense fallback={null}>
              <ContentFileMedia listIdFiles={files} />
            </Suspense>
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(DeliveredScreen)
