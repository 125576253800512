import styled from "styled-components"
import { boxShadow, displays, textuppercase } from "@Theme/base/Variables"
import { CardItemStyled } from "@Theme/common/CardItem.styled"

export const ContentOrderItemstyled = styled.div<{ selected?: boolean }>`
  ${displays.flexBase};
  background: ${({ theme, selected }) => (selected ? theme.palette.grey10 : theme.palette.grey5)};
  border-radius: 13px;

  & .content-order-checked {
    ${displays.flexCenter};
    padding-left: 0.5rem;
  }

  &:hover {
    ${boxShadow.shadow02};
  }
`

export const CardOrderItemStyled = styled(CardItemStyled)`
  position: relative;
  padding: 0.5rem;
  width: 100%;

  & .content-item-order {
    ${displays.flexColumn}
    align-items:flex-start;
    gap: 0.3rem;

    & > .item {
      ${displays.flexBase};
      gap: 0.3rem;
      font-size: 0.714rem;
      color: ${({ theme }) => theme.palette.grey60};
      ${textuppercase};

      & span {
        color: ${({ theme }) => theme.palette.grey90};
        font-weight: bold;
      }
    }
  }
  & .tag {
    ${displays.flexBase};
    gap: 5px;
    position: absolute;
    top: 6px;
    right: 20px;
    font-size: 0.714rem;
    & > svg {
      font-size: 1.5rem;
    }
  }

  & .icon-arrow-rigth {
    position: absolute;
    color: ${({ theme }) => theme.palette.secondary};
    font-size: 1.5rem;
    right: 5px;
  }
`
