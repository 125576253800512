import { FunctionComponent, Suspense, useCallback, useContext, useEffect, useRef } from "react"
import { RouteComponentProps } from "react-router-dom"
import OrderList from "@Components/Core/Order/OrderList"
import OrderContainerStyled from "@Components/Core/Order/OrderList.styled"
import useGetOrderByStatus from "@Hooks/useGetOrderByStatus"
import IPage from "@Interfaces/IPage"
import Logger from "@Classes/Logger"
import SkeletonGrid from "@Components/Commons/Skeleton/SkeletonGrid"
import BackBotton from "@Components/Commons/BackBotton/BackBotton"
import Paginate from "@Components/Commons/Pagination/Paginate"
import OrderFilterDate from "@Components/Core/Order/FilterDate/OrderFilterDate"
import ErrorHandled from "@Components/Commons/Error/ErrorHandler"
import SearchForm from "@Components/SearchForm/SearchForm"
import { ISearchResults } from "@Interfaces/ISearchResults"
import SortOrder from "@Components/Core/Order/Sort/SortOrder"
import { AuthContext } from "@Context/context/AuthContext"
import { clients } from "@Webapp-Clients/Clients"

const OrderScreen: FunctionComponent<IPage & RouteComponentProps<any>> = (props: any) => {
  const {
    name,
    match: {
      params: { type },
    },
  } = props

  const {
    state: { user },
  } = useContext(AuthContext)

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const CustomFilters = client["CustomFilters" as keyof typeof client]

  const refElemOrders = useRef<HTMLDivElement>(null)

  const {
    ordersGroup,
    loading,
    setPage,
    maxPage,
    onSelectedRangeDate,
    page,
    setSearch,
    onChangeSortDate,
    handleChangeCustomFilters,
  } = useGetOrderByStatus(type)

  const handleClickPage = (pageSeleted: number) => {
    setPage(pageSeleted)
  }

  const handleClickSearch = useCallback(
    ({ query, optionFilter }: ISearchResults) => {
      if (optionFilter) {
        setSearch({
          [optionFilter]: query,
        })
      }
    },
    [setSearch],
  )

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  return (
    <div ref={refElemOrders}>
      <BackBotton>
        <OrderFilterDate onSelectedRangeDate={onSelectedRangeDate} />
      </BackBotton>
      <SearchForm onClickSearch={handleClickSearch} />
      <OrderContainerStyled hasCustomFilters={!!CustomFilters}>
        <section className="section-filters">
          {CustomFilters && (
            <Suspense fallback={null}>
              <CustomFilters onChangeCustomFilters={handleChangeCustomFilters} />
            </Suspense>
          )}
          <SortOrder handleChangeSort={onChangeSortDate} disabled={loading} />
        </section>

        {loading ? (
          <SkeletonGrid count={3} isGroupOrder={true} />
        ) : (
          <>
            {ordersGroup ? (
              <>
                <OrderList ordersGroup={ordersGroup} />
                {maxPage > 1 && (
                  <Paginate
                    pageCount={maxPage}
                    onChangePage={handleClickPage}
                    elementFocus={refElemOrders}
                    pageInit={page}
                  />
                )}
              </>
            ) : (
              <ErrorHandled message="Sin resultados para esta busqueda" />
            )}
          </>
        )}
      </OrderContainerStyled>
    </div>
  )
}

export default OrderScreen
