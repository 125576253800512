import { COUNT_MAX_FILE, SUPPORTED_FORMATS } from "@Helpers/constHelper"
import * as Yup from "yup"

export const schemaLoginForm = Yup.object({
  user: Yup.string().required("Usuario es requerido"),
  password: Yup.string().required("Contraseña es requerida"),
})

export const shemaSearchForm = Yup.object({
  search: Yup.string(),
})

export const shemaFileUpladerForm = Yup.object({
  filesShippinGroup: Yup.mixed<FileList>()
    .test("required", `Debe selecionar al menos un archivo y máximo ${COUNT_MAX_FILE}`, (value) => {
      if (!value) return false
      return value.length > 0 && value.length <= COUNT_MAX_FILE
    })
    .test("type", "Solo se acepta archivos en formato JPEG/PNG ", function (value) {
      if (!value || value.length === 0) return false
      return [...value].every(({ type }) => SUPPORTED_FORMATS.includes(type))
    })
    .test("fileSize", "Los Archivos no deben superar 5M", (value) => {
      if (!value) return false
      return [...value].every(({ size }) => {
        const fileSize = Math.round(size / 1024)
        return fileSize <= 5120
      })
    }),
})
