import { useEffect, FunctionComponent, useRef, useCallback } from "react"
import BackBotton from "@Components/Commons/BackBotton/BackBotton"
import config from "@Config/configEnv"
import IPage from "@Interfaces/IPage"
import { ISearchResults } from "@Interfaces/ISearchResults"
import { SecondtitleStyled } from "@Theme/common/Paragraph.styled"
import { RouteComponentProps } from "react-router-dom"
import Logger from "@Classes/Logger"

import OrderContainerStyled from "@Components/Core/Order/OrderList.styled"
import useGetOrderByStatus from "@Hooks/useGetOrderByStatus"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"
import SkeletonGrid from "@Components/Commons/Skeleton/SkeletonGrid"
import OrderList from "@Components/Core/Order/OrderList"
import ErrorHandled from "@Components/Commons/Error/ErrorHandler"
import Paginate from "@Components/Commons/Pagination/Paginate"
import SearchForm from "@Components/SearchForm/SearchForm"
import { OptionsFilterEnum } from "@Enums/OptionsFilterEnum"

const SearchResultsScreen: FunctionComponent<IPage & RouteComponentProps<ISearchResults>> = (
  props: RouteComponentProps<ISearchResults> & IPage,
) => {
  const {
    name,
    match: {
      params: { query, optionFilter },
    },
  } = props

  const refElemOrders = useRef<HTMLDivElement>(null)

  const optionfilter = OptionsFilterEnum[optionFilter as keyof typeof OptionsFilterEnum]
  const filterOrder = optionfilter
    ? { [optionfilter]: query }
    : { [OptionsFilterEnum.query]: query }

  const { ordersGroup, loading, maxPage, setPage, page, setSearch } = useGetOrderByStatus(
    EShippinggroupStatus.all.toString(),
    filterOrder,
  )

  const handleClickPage = (pageSeleted: number) => {
    setPage(pageSeleted)
  }

  const handleClickSearch = useCallback(
    ({ query, optionFilter }: ISearchResults) => {
      if (optionFilter) {
        setSearch({
          [optionFilter]: query,
        })
      }
    },
    [setSearch],
  )

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  return (
    <>
      <BackBotton>
        <SecondtitleStyled>{`Busqueda por "${query}"`}</SecondtitleStyled>
      </BackBotton>
      <OrderContainerStyled>
        <>
          <SearchForm
            search={query}
            optionFilter={optionFilter}
            onClickSearch={handleClickSearch}
          />

          {loading ? (
            <SkeletonGrid count={config.maxRecords} isGroupOrder={true} />
          ) : (
            <>
              {ordersGroup ? (
                <>
                  <OrderList ordersGroup={ordersGroup} showStatus />
                  {maxPage > 1 && (
                    <Paginate
                      pageCount={maxPage}
                      onChangePage={handleClickPage}
                      elementFocus={refElemOrders}
                      pageInit={page}
                    />
                  )}
                </>
              ) : (
                <ErrorHandled message="Sin resultados para esta busqueda" />
              )}
            </>
          )}
        </>
      </OrderContainerStyled>
    </>
  )
}

export default SearchResultsScreen
