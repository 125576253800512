import axiosClient from "@Config/axiosClient"
import { IResponseService } from "@Interfaces/IResponseService"
import { AxiosResponse } from "axios"
import { IRequestService, IRequestServiceLogin } from "@Interfaces/IRequestService"
import { ILoginInformation } from "@Interfaces/IUser"
import configEnv from "@Config/configEnv"
import { ISource } from "@Interfaces/ISource"

const clientAxios = axiosClient("OSM")
const clientSxiosOIM = axiosClient("OIM")

export const authService = async (
  user: IRequestServiceLogin,
): Promise<AxiosResponse<IResponseService<ILoginInformation>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlUser}`

  const response = await client.post<IResponseService<ILoginInformation>>(
    url,
    {
      ...user,
      options: {
        permission: true,
      },
    },
    {
      headers: { "Content-Type": "application/json" },
    },
  )

  return response
}

export const getTypologyBySources = async (
  token: string,
  requestBody: IRequestService,
): Promise<AxiosResponse<IResponseService<Array<ISource>>>> => {
  const client = clientSxiosOIM.getClient()

  clientSxiosOIM.setTokenAuth(token)

  const url = `${configEnv.urlSources}`

  const response = await client.post<IResponseService<Array<ISource>>>(url, requestBody, {
    headers: { "Content-Type": "application/json" },
  })

  return response
}
