import { useContext } from "react"
import { ROUTE_ORDER } from "@Helpers/constHelper"
import { AuthContext } from "@Context/context/AuthContext"
import { sideBarData } from "@Theme/assets/Data/SideBarData"
import { summaryOptions } from "@Theme/assets/Data/SummaryOptionsData"
import { IMenu } from "@Interfaces/IMenu"
import { useTranslation } from "react-i18next"
import { keyBy } from "@Helpers/UtilsHelper"

/*

Hook to handle the Menu of the User (List of states available)


*/

const UseGetMenuUser = () => {
  const { t } = useTranslation()

  const {
    state: { optionsMenuAccess },
  } = useContext(AuthContext)

  /**
   * the getMenuSideBar return Array with the information of siderBarData
   *
   * @returns {siderBarData[]}
   */
  const getMenuSideBar = () => {
    const indexMenuOrders = sideBarData.findIndex(({ path }) => path.includes(ROUTE_ORDER))

    if (indexMenuOrders !== -1) {
      sideBarData[indexMenuOrders].subNav = getMenuSummaryOrder()
    }

    return sideBarData
  }

  /*

  Return Array with the information required to display the List of States 

  @param {String} status - Order/SG status of OMNIX 
  @return {<IMenu>[]} Array with the required information


  */
  const getMenuSummaryOrder = (): Array<IMenu> => {
    const keyBySummaryOptions = keyBy(summaryOptions, "status")
    return optionsMenuAccess?.map((option) => {
      const summaryOption = keyBySummaryOptions[option.statusOrder]
      return {
        ...summaryOption,
        title: t(`${summaryOption?.status}.title`),
        subTitle: t(`${summaryOption?.status}.subTitle`),
        path: summaryOption?.path || "",
        optionCount: option.optionCount,
      }
    })
  }

  return {
    getMenuSideBar,
    getMenuSummaryOrder,
  }
}

export default UseGetMenuUser
