let maxRecords = Number(process.env.REACT_APP_MAX_RECORDS)
maxRecords = isNaN(maxRecords) ? 10 : maxRecords

const configEnv = {
  urlOder: process.env.REACT_APP_API_URL_ORDER,
  maxRecords,
  urlItems: process.env.REACT_APP_API_URL_ITEMS,
  urlSources: process.env.REACT_APP_API_URL_SOURCES_OIM,
  urlUser: process.env.REACT_APP_API_URL_USER,
  retriesReques: process.env.REACT_APP_RETRIES_REQUEST,
  urlValidateSeries: process.env.REACT_APP_API_URL_SERIES,
  urlValidateSimcard: process.env.REACT_APP_API_URL_SIMCARD,
  urlUpdateDelivery: process.env.REACT_APP_API_URL_COURIER_UPDATE_DELIVERY,
  urlConfirmDelivery: process.env.REACT_APP_API_URL_COURIER_CONFIRM_DELIVERY,
  urlGenerateSgDocuments: process.env.REACT_APP_API_URL_GENERATE_DOCS,
}

export default configEnv
