import { useState, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { attachFileShippingGroup, executeShipingGroupAction } from "@Services/ShippingGroupService"
import Logger from "@Classes/Logger"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { NOTIFY_DELIVERED } from "@Helpers/constHelper"
import { ProcessShippingGroupActionType } from "@Types/OrderType"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import { FilesFormType } from "@Types/FilesFormType"

const useTransitOrder = ({
  shippingGroupId,
  setFinishedShippingGroupAction,
}: ProcessShippingGroupActionType) => {
  const [loadingTransit, setLoadingTransit] = useState(false)

  const { notification, errorHander } = useContext(GlobalContext)

  const attachFile = async (filesShippinGroup: FileList): Promise<boolean> => {
    const formAttachFile = new FormData()

    for (let indexFile = 0; indexFile < filesShippinGroup.length; indexFile++) {
      const file = filesShippinGroup.item(indexFile)
      if (file) formAttachFile.append(`$attach${indexFile}`, file)
    }

    formAttachFile.append("tags", [...filesShippinGroup].map(({ name }) => name).join(","))
    const {
      data: { code },
    } = await attachFileShippingGroup(shippingGroupId, formAttachFile)

    return code === TransactionCodeEnum.ok
  }

  const deliveredOrder = async () => {
    const {
      data: { code },
    } = await executeShipingGroupAction({
      shippingGroupId,
      action: EShippinggroupAction.delivered,
    })

    if (code === TransactionCodeEnum.ok) {
      setFinishedShippingGroupAction(true)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: "Productos para entregar correctamente",
          title: NOTIFY_DELIVERED,
          type: "success",
        },
      })
    }
  }

  const submitDeliveredOrder = async ({ filesShippinGroup }: FilesFormType) => {
    try {
      setLoadingTransit(true)

      let successAttachFiles: boolean = true

      if (filesShippinGroup) {
        successAttachFiles = await attachFile(filesShippinGroup)
      }

      if (!successAttachFiles) {
        notification?.dispatch({
          type: "ADD_NOTIFICATION",
          payload: {
            message: "Ha ocurrido un error, al cargar los archivo",
            title: NOTIFY_DELIVERED,
            type: "danger",
          },
        })

        return
      }

      await deliveredOrder()

      setLoadingTransit(false)
    } catch (error: any) {
      setLoadingTransit(false)
      Logger.error(error)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  return {
    loadingTransit,
    submitDeliveredOrder,
  }
}

export default useTransitOrder
