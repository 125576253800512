import {
  IconCheck,
  IconPackage,
  IconClock,
  IconTruck,
  IconTruckDelivery,
  IconBoxUnpacking,
  IconSquareClose,
  IconCancel,
} from "@Theme/common/Icon.styled"
import { IMenu } from "@Interfaces/IMenu"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"

export const summaryOptions: IMenu[] = [
  {
    title: "",
    subTitle: "",
    path: "/order/confirmation",
    icon: <IconCheck />,
    status: EShippinggroupStatus.confirmation,
  },
  {
    title: "",
    subTitle: "",
    path: "/order/package",
    icon: <IconPackage />,
    status: EShippinggroupStatus.package,
  },
  {
    title: "",
    subTitle: "",
    path: "/order/delivery",
    icon: <IconTruckDelivery />,
    status: EShippinggroupStatus.delivery,
  },
  {
    title: "",
    subTitle: "",
    path: "/order/transit",
    icon: <IconTruck />,
    status: EShippinggroupStatus.transit,
  },

  {
    title: "",
    subTitle: "",
    path: "/order/transit_to_source",
    stateLocation: {
      fromTransitToSource: true,
    },
    icon: <IconTruck />,
    status: EShippinggroupStatus.transit_source,
  },
  {
    title: "",
    subTitle: "",
    path: "/order/pickup",
    icon: <IconClock />,
    status: EShippinggroupStatus.pickup,
  },
  {
    title: "",
    subTitle: "",
    path: "/order/pickup_in_my_source",
    stateLocation: {
      fromTransitToSource: true,
    },
    icon: <IconClock />,
    status: EShippinggroupStatus.pickup_source,
  },
  
  {
    title: "",
    subTitle: "",
    path: "/order/delivered",
    icon: <IconBoxUnpacking />,
    status: EShippinggroupStatus.delivered,
  },
  {
    title: "",
    subTitle: "",
    path: "/order/closed",
    icon: <IconSquareClose />,
    status: EShippinggroupStatus.closed,
  },

  {
    title: "",
    subTitle: "",
    path: "/order/canceled",
    icon: <IconCancel />,
    status: EShippinggroupStatus.canceled,
  },
]
