import axiosClient from "@Config/axiosClient"
import { IResponseService } from "@Interfaces/IResponseService"
import { IOrder } from "@Interfaces/IOrder"
import configEnv from "@Config/configEnv"
import { AxiosResponse, CancelToken } from "axios"

const clientAxios = axiosClient("OOM")

export const getOrderById = async (
  orderId: string,
  cancelToken: CancelToken,
  status: boolean = false,
): Promise<AxiosResponse<IResponseService<IOrder>>> => {
  const client = clientAxios.getClient()

  const url = `${configEnv.urlOder}/${orderId}?status=${status}`

  const response = await client.get<IResponseService<IOrder>>(url, {
    headers: { "Content-Type": "application/json" },
    cancelToken,
  })

  return response
}
