const getCustomValue = (value: string) => {
  if ((window.location.href).includes("privilege")) {
    switch (value) {
      case "transit_to_source":
        return "IN_TRANSIT"
      case "pickup_in_my_source":
        return "READY_FOR_PICKUP"
      default:
        return ""
    }
  }
  return null
}

export enum EShippinggroupStatus {
  confirmation = "AWAITING_STORE_CONFIRMATION" as any,
  package = "PENDING_PACKAGE" as any,
  delivery = "AWAITING_DELIVERY" as any,
  transit = "IN_TRANSIT" as any,
  transit_source = "IN_TRANSIT_SOURCE" as any,
  pickup = "READY_FOR_PICKUP" as any,
  pickup_source = "READY_FOR_PICKUP_SOURCE" as any,
  closed = "CLOSED" as any,
  delivered = "DELIVERED" as any,
  canceled = "CANCELED" as any,
  rejected = "REJECTED" as any,
  all = "all" as any,
  // TODO: To call the services correctly, custom paths are read independently of the original states and counter states, this could require refactoring. 
  transit_to_source = getCustomValue("transit_to_source") as any,
  pickup_in_my_source = getCustomValue("pickup_in_my_source") as any,
  // ---
}

export enum EShippinggroupAction {
  accept = "accept",
  packed = "packed",
  shipped = "shipped",
  delivered = "delivered",
  picked_up = "picked_up",
  reject = "reject",
  cancel = "cancel",
  cancelLogistic = "cancel_logistic",
}
