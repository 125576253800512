import { useState, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { executeShipingGroupAction } from "@Services/ShippingGroupService"
import Logger from "@Classes/Logger"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { NOTIFY_PICKED } from "@Helpers/constHelper"
import { ProcessShippingGroupActionType } from "@Types/OrderType"
import { EShippinggroupAction } from "@Enums/shippinggroupEmun"
import useUserPermissions from "@Hooks/UseUserPermissions"
import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"

const usePickupOrder = ({
  setFinishedShippingGroupAction,
  shippingGroupId,
  isElocker,
}: ProcessShippingGroupActionType) => {
  const [loadingPickup, setLoadingPickup] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)

  const { notification, errorHander } = useContext(GlobalContext)

  const { permission } = useUserPermissions({
    resources: [
      ResourcePermissionsEnum.pickupElocker,
      ResourcePermissionsEnum.cancelOrder,
      ResourcePermissionsEnum.qrEntryCancel,
      ResourcePermissionsEnum.qrExitCancel,
      ResourcePermissionsEnum.orderPickupCancel,
    ],
  })

  const handleClickPickupAction = async () => {
    try {
      setLoadingPickup(true)
      await executeAction(EShippinggroupAction.picked_up, "Productos entregado correctamente")
      setLoadingPickup(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingPickup(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const handleClickCancelAction = async () => {
    try {
      setLoadingCancel(true)
      await executeAction(EShippinggroupAction.cancel, "Orden cancelada correctamente")
      setLoadingCancel(false)
    } catch (error: any) {
      Logger.error(error)
      setLoadingCancel(false)
      errorHander?.dispatch({ hasError: true, code: error.response?.status })
    }
  }

  const executeAction = async (action: EShippinggroupAction, messageResul: string) => {
    const {
      data: { code },
    } = await executeShipingGroupAction({
      shippingGroupId,
      action,
    })

    if (code === TransactionCodeEnum.ok) {
      setFinishedShippingGroupAction(true)

      notification?.dispatch({
        type: "ADD_NOTIFICATION",
        payload: {
          message: messageResul,
          title: NOTIFY_PICKED,
          type: "success",
        },
      })
    }
  }

  const canCancelOrder = () => {
    if (window.$website === "wom") {
      return permission[ResourcePermissionsEnum.orderPickupCancel]
    } else {
      return permission[ResourcePermissionsEnum.cancelOrder]
    }
  }

  return {
    handleClickPickupAction,
    handleClickCancelAction,
    loadingPickup,
    loadingCancel,
    hasPermissionQREntry: permission[ResourcePermissionsEnum.qrEntryCancel],
    hasPermissionQRExitCancel: permission[ResourcePermissionsEnum.qrExitCancel],
    denyPickupAction: isElocker && !permission[ResourcePermissionsEnum.pickupElocker],
    canCancelOrder: canCancelOrder(),
  }
}

export default usePickupOrder
