import { ResourcePermissionsEnum } from "@Enums/ResourcePermissionsEnum"
import { EShippinggroupAction, EShippinggroupStatus } from "@Enums/shippinggroupEmun"

export const ROUTE_ORDER = "/order"
export const WEB_APP_USER = "WEB_APP_USER"
export const TODAY = "HOY"
export const YERTERDAY = "AYER"

export const STORAGE_SORT_ORDER = "STORAGE_SORT_ORDER"

export const DEFAULT_CHANNEL = "ecommerce"

export const STORAGE_PAGE_CURRENT_ORDERS = "STORAGE_PAGE_CURRENT_ORDERS"

export const ERROR_GENERIC = "Página no encontrada"

export const NOTIFY_PICKING = "Picking"

export const NOTIFY_PACKING = "Packing"

export const NOTIFY_SHIPPED = "shipped"

export const NOTIFY_DELIVERED = "Delivered"

export const NOTIFY_PICKED = "Picked"

export const ERROR_LOGIN =
  "El usuario o la contraseña que ingresaste no coinciden con nuestros registros. Por favor, revisa e inténtalo de nuevo. "

export const Error_GNERAL =
  "Ha ocurrido un error inesperado en la aplicación. por favor intente nuevamente"

export const Error_Source =
  "La bodega asignada para este usuario no esta configurada, para mas información contacte con el administrador del sistema"

export const FILE_NAME_DEFAULT_DOCUMENTATION = "Guía de despacho"

export const MESSAGE_RETIES_ELOCKER =
  "Por favor refresque su navegador para ver el código QR o de retiro, si aún no lo ha visto"

export const GROUP_SERVICE_SERIES = "GROUP_SERVICE_SERIES"

export const WEB_SITES = ["localhost", "wom", "privilege", "sandbox"]

// TODO: Make interface
export const THEME_CONFIG: any = {
  core: {
    id: "core"
  },
  privilege: {
    id: "privilege"
  },
  sandbox: {
    id: "sandbox"
  },
  wom: {
    id: "wom"
  },
}

export const MULTIPLE_SELECT_ORDERS_SG = [
  {
    status: EShippinggroupStatus.package,
    action: EShippinggroupAction.packed,
    permission: ResourcePermissionsEnum.packingGroup,
  },
  {
    status: EShippinggroupStatus.delivery,
    action: EShippinggroupAction.shipped,
    permission: ResourcePermissionsEnum.shippingGroup,
  },
] as const

export const STATUS_PERMISSIONS = [
  {
    status: EShippinggroupStatus.confirmation,
    permission: ResourcePermissionsEnum.picking,
  },
  {
    status: EShippinggroupStatus.package,
    permission: ResourcePermissionsEnum.packingManual,
  },
  {
    status: EShippinggroupStatus.delivery,
    permission: ResourcePermissionsEnum.shipping,
  },
  {
    status: EShippinggroupStatus.transit,
    permission: ResourcePermissionsEnum.delivered,
  },
  {
    status: EShippinggroupStatus.transit_source,
    permission: ResourcePermissionsEnum.deliveredSource,
  },
  {
    status: EShippinggroupStatus.pickup,
    permission: ResourcePermissionsEnum.pickupManual,
  },
  {
    status: EShippinggroupStatus.pickup_source,
    permission: ResourcePermissionsEnum.readyForPickupSource,
  },
  {
    status: EShippinggroupStatus.delivered,
    permission: ResourcePermissionsEnum.deliveredfinal,
  },
  {
    status: EShippinggroupStatus.closed,
    permission: ResourcePermissionsEnum.deliveredfinal2,
  },
  {
    status: EShippinggroupStatus.canceled,
    permission: ResourcePermissionsEnum.deliveredfinalcancel,
  },
  {
    status: EShippinggroupStatus.rejected,
    permission: ResourcePermissionsEnum.rejectedOrderStatus,
  },
]

export const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"]
export const COUNT_MAX_FILE: number = 2
