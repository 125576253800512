import { FunctionComponent, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Logger from "@Classes/Logger"
import SummaryOptions from "@Components/Summary/SummaryOptions"
import IPage from "@Interfaces/IPage"
import UseGetMenuUser from "@Hooks/UseGetMenuUser"
import UseGroupedOrderByCount from "@Hooks/UseGroupedOrderByCount"
import SearchForm from "@Components/SearchForm/SearchForm"
import { ISearchResults } from "@Interfaces/ISearchResults"
const DashBordScreen: FunctionComponent<IPage> = (props: any) => {
  const { name } = props
  const history = useHistory()

  const { getMenuSummaryOrder } = UseGetMenuUser()

  UseGroupedOrderByCount()

  const summaryMenuOrder = getMenuSummaryOrder()

  useEffect(() => {
    Logger.info(`Loading ${name}`)
  }, [name])

  const handleClickSearch = useCallback(
    ({ query, optionFilter }: ISearchResults) => {
      if (query) history.push(`/search/${query}/${optionFilter}`)
    },
    [history],
  )

  return (
    <>
      <SearchForm onClickSearch={handleClickSearch} />
      {summaryMenuOrder && <SummaryOptions summaryOptions={summaryMenuOrder} />}
    </>
  )
}

export default DashBordScreen
