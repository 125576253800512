import { useContext, useEffect } from "react"
import { IRequestServiceLogin } from "@Interfaces/IRequestService"
import { authService, getTypologyBySources } from "@Services/AuthServices"
import Logger from "@Classes/Logger"
import { ILoginBody } from "@Interfaces/ILoginBody"
import { AuthContext } from "@Context/context/AuthContext"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { Error_GNERAL, ERROR_LOGIN, STATUS_PERMISSIONS } from "@Helpers/constHelper"
import { ISource } from "@Interfaces/ISource"
import useUserPermissions from "./UseUserPermissions"
import JsonEngine from "@Classes/JsonEngine"

/*

 Hook to handle all login functions (Login/Logout)


@return {Boolean} loading .Flag to know when the information is ready to be used
@return {String} error  .Error provide by the context
@return {Function} loginUser function to make the request to log in the user
@return {Function} logout function to logout and clean the localstorage

*/

const useLogin = () => {
  const {
    dispatch,
    state: { error, loading, optionsMenuAccess, user },
  } = useContext(AuthContext)

  const { permission: userPermission } = useUserPermissions({
    resources: STATUS_PERMISSIONS.map(({ permission }) => permission),
  })

  // Check the permisions of the current user and update the menu to display the required states
  useEffect(() => {
    if (user && optionsMenuAccess.length === 0) {
      const menuAccess = STATUS_PERMISSIONS.filter(
        ({ permission }) => userPermission[permission],
      ).map(({ status }) => ({
        statusOrder: status,
      }))
      if (menuAccess.length > 0) {
        dispatch({ type: "[auth] Set menu user", payload: menuAccess })
      }
    }
  }, [optionsMenuAccess, user, userPermission, dispatch])

  /*
   * Make a request to backEnd to get the user info
   * @param {Object <ILoginBody>} formValues - Object with User (String) and Password(String)
   * @return {void} Nothing
   */

  const loginUser = async (formValues: ILoginBody) => {
    const transformBody: IRequestServiceLogin = {
      key: formValues.user,
      secret: formValues.password,
    }
    try {
      dispatch({ type: "[auth] Login start" })

      const responseLogin = await authService(transformBody)
      const {
        data: { message: infoLogin },
      } = responseLogin

      const groupIcds = infoLogin.groups.map(({ id }) => id)

      const infoSources = await getTipologyByGroups(infoLogin.token, groupIcds)

      infoLogin.currentSources = infoSources.map(({ id }) => id)

      dispatch({
        type: "[auth] Login success",
        payload: infoLogin,
      })
    } catch (error: any) {
      Logger.error(error)

      if (error.response?.status === TransactionCodeEnum.unauthorized)
        dispatch({ type: "[auth] Login error", payload: ERROR_LOGIN })
      else dispatch({ type: "[auth] Login error", payload: Error_GNERAL })
    }
  }

  //Send to the context the log out action and clean the user information in the local storage
  const logout = () => {
    dispatch({ type: "[auth] Logout" })
    JsonEngine.clear()
  }

  /*
   * Make a request to backEnd to know what are the available Source for the current user (check if the sources returned by the user exist in the database)
   * @param {String} token - token returned by the login for the current user
   * @param {String []} groups - Current Value to replace "value" in valuesGenerate (filtersInfo.js)
   * @return {Object<ISource> []} Array with the information of the sources
   */
  const getTipologyByGroups = async (
    token: string,
    groups: Array<string>,
  ): Promise<Array<ISource>> => {
    const {
      data: { message: infoSources },
    } = await getTypologyBySources(token, {
      maxRecords: 50,
      skipRecords: 0,
      project: ["id", "name", "custom"],
      idsOrAliases: groups,
    })

    return infoSources
  }

  return {
    loading,
    error,
    loginUser,
    logout,
  }
}

export default useLogin
