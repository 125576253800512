import AxiosClient from "@Classes/AxiosClient"

const axiosClassOOM = new AxiosClient("OOM")
const axiosClassOIM = new AxiosClient("OIM")
//const axiosClassOLM = new AxiosClient("OLM")
//const axiosClassOMA = new AxiosClient("OMA")
const axiosClassOSM = new AxiosClient("OSM")

const axiosClassServiceListener = new AxiosClient("LISTENER")

export default function axiosClient(client: string): AxiosClient {
  switch (client) {
    case "OOM":
      return axiosClassOOM
    case "OIM":
      return axiosClassOIM

    case "OSM":
      return axiosClassOSM

    case "LISTENER":
      return axiosClassServiceListener
    /*
    case "OMA":
      return axiosClassOMA

    case "OLM":
      return axiosClassOLM
      */
    default:
      return axiosClassOOM
  }
}
