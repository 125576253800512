import { useEffect, useContext } from "react"
import { GlobalContext } from "@Context/context/GlobalContext"
import { getGroupedOrder } from "@Services/ShippingGroupService"
import { AuthContext } from "@Context/context/AuthContext"
import Logger from "@Classes/Logger"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { IRequestGroupedOrder } from "@Interfaces/IOrder"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"

/*
  Hook to handle the main menu counter for the SG states
*/
const UseGroupedOrderByCount = () => {
  const {
    state: { user, optionsMenuAccess },
    dispatch,
  } = useContext(AuthContext)

  const { errorHander } = useContext(GlobalContext)

  /*
   * Make a request to backEnd to get the quantity of the SGS in the sources available for the current user
   * after obtain the information dispatch a action to save the information in the context
   * @return {void} Nothing
   */
  useEffect(() => {
    const groupedOrder = async () => {
      if (!user?.currentSources) {
        Logger.error("No found source")
        return
      }
      try {
        let requestGroupedOrder: IRequestGroupedOrder = {
          sources: user.currentSources,
        }

        if (
          optionsMenuAccess.some(
            ({ statusOrder }) => statusOrder === EShippinggroupStatus.transit_source,
          )
        ) {
          requestGroupedOrder.targetSources = user.currentSources
        }

        const {
          data: { message: groupedOrders },
        } = await getGroupedOrder(requestGroupedOrder)

        dispatch({ type: "[auth] Update Menu user", payload: groupedOrders })
      } catch (error: any) {
        Logger.error(error)
        if (error.response?.status === TransactionCodeEnum.notFound) {
          dispatch({ type: "[auth] Update Menu user", payload: [] })
          return
        }
        errorHander?.dispatch({ hasError: true, code: error.response?.status })
      }
    }
    groupedOrder()
    //eslint-disable-next-line
  }, [user?.currentSources])
}

export default UseGroupedOrderByCount
