import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router"
import { getOrderByStatus } from "@Services/ShippingGroupService"
import Logger from "@Classes/Logger"
import { OderGroupingType } from "@Types/OrderType"
import { IOrderShippinggroup } from "@Interfaces/IOrder"
import { getValueShippinggroupStatusEnum, groupBy } from "@Helpers/UtilsHelper"
import { formatDate } from "@Helpers/FormatDateHelper"
import configEnv from "@Config/configEnv"
import { STORAGE_SORT_ORDER, TODAY, YERTERDAY } from "@Helpers/constHelper"
import useCancelToken from "@Hooks/UseCancelToken"
import { IRequestService } from "@Interfaces/IRequestService"
import { IRangeExtend } from "@Interfaces/IContentCelendar"
import { TransactionCodeEnum } from "@Enums/TransactionCodeEnum"
import { AuthContext } from "@Context/context/AuthContext"
import { GlobalContext } from "@Context/context/GlobalContext"
import { typeVariantObject } from "@Types/CommonsTypes"
import { OptionsFilterEnum } from "@Enums/OptionsFilterEnum"
import { SortOrderEnum } from "@Enums/OrderEnum"
import useLocalStorage from "./useLocalStorage"
import { ILocationState } from "@Interfaces/IMenu"
import { EShippinggroupStatus } from "@Enums/shippinggroupEmun"

const INITIAL_PAGE = 0

/*

Hook to handle SG List by Status 

@param {String} status - Status of the SG in OMNIX
@param {Object} filterOrder - Filter properties sent in the body of the request
@return {OderGroupingType} ordersGroup Object with the order info
@return {Boolean} loading flag to know when the information is ready to be used
@return {Function} setPage fuction to change page state
@return {Integer} maxPage Int value of maximun pages posible with the body send in the request
@return {Integer} page Int value of the current page 
@return {Function} onSelectedRangeDate  function to set the date range of the request  and reset the current page
@return {Function} setSearch  function to set search state

*/

const useGetOrderByStatus = (
  status: string,
  filterOrder: typeVariantObject = { [OptionsFilterEnum.query]: undefined },
) => {
  const [ordersGroup, setOrdersGroup] = useState<OderGroupingType>({})
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(INITIAL_PAGE)
  const [maxPage, setMaxPage] = useState(0)
  const [rangeDate, setRangeDate] = useState<IRangeExtend>()
  const [search, setSearch] = useState<typeVariantObject>(filterOrder)
  const [customFilters, setCustomFilters] = useState<typeVariantObject | null>(null)
  const [sort, setSort] = useLocalStorage(STORAGE_SORT_ORDER, SortOrderEnum.desc)

  const location = useLocation<ILocationState>()

  const {
    state: { user },
  } = useContext(AuthContext)

  // TODO: Refactor next block
  const authState = useContext(AuthContext)
  const website: any = authState?.state.user?.mainGroup.id

  const { errorHander } = useContext(GlobalContext)

  const { isCancel, newCancelToken } = useCancelToken()
  let sorting: any = window.$website === "wom" ? {
    "status.date": {
      order: sort,
      mode: "min",
    }
  } : { orderCreationDate: sort };

  //Make the request to build the current list of SGS
  useEffect(() => {
    const getOrdersGroupByDate = async () => {
      const statusValue: any = getValueShippinggroupStatusEnum(status)
      if (!user?.currentSources) {
        Logger.error("Not found sources")
        return
      }

      const requestBody: IRequestService = {
        maxRecords: configEnv.maxRecords,
        skipRecords: page * configEnv.maxRecords,
        filter: {
          //[sourceFilter]: user.currentSources,
          shippingType: ["SP", "HD"],
        },

        sort: sorting,
        // sort: {
        //   orderCreationDate: sort,
        // },
        project: [
          "orderId",
          "orderCreationDate",
          "id",
          "channel",
          "shippingType",
          "salesChannelId",
          "source.id",
          "source.name",
          "target.id",
          "target.name",
          "target.customer",
          "currentStatus",
          "custom.infoElocker",
          "custom.consolidation",
          "target.source.id",
          "custom.courierDeliveryInfo",
          "status"
        ],
        range: rangeDate
          ? {
              from: `${
                rangeDate.startDate && formatDate(rangeDate.startDate, "yyyy-MM-dd")
              } 00:00:00`,
              to: `${rangeDate.endDate && formatDate(rangeDate.endDate, "yyyy-MM-dd")} 23:59:59`,
              type: "absolute",
            }
          : undefined,
      }

      if (search.hasOwnProperty(OptionsFilterEnum.query)) {
        requestBody.query = search[OptionsFilterEnum.query]
      } else {
        requestBody.filter = {
          ...requestBody.filter,
          ...search,
        }
      }

      /// custom filters
      if (customFilters) {
        requestBody.filter = {
          ...requestBody.filter,
          ...customFilters,
        }
      }

      // Initial values
      let sourceFilter = "source.id"
      const ListValidshippingTypes: any = {
        shippingType: ["SP", "HD"],
      }
      const specialSourceStates = ["IN_TRANSIT", "READY_FOR_PICKUP"] 
      // TODO: Make interface
      let purgedOrders: any = []
      let response: any = {}
      let responseRecords = []
      let responseTotalRecordsMatched: number = 0
      let responseTargetRecords: any[] = []
      let responseTargetTotalRecordsMatched: number = 0
      let responseError: any = null
      let orders: any

      if (website === "privilege") {
        sourceFilter = location.state?.fromTransitToSource ? "target.source.id" : "source.id"
      }

      // Block render page
      setLoading(true)

      // GET Information from orders with source.id filter active
      try {
        requestBody.filter = {
          ...requestBody.filter,
          ...ListValidshippingTypes,
          [sourceFilter]: user.currentSources,
        }
        response = await getOrderByStatus(statusValue, requestBody, newCancelToken())
        response = response.data.message
        responseRecords = response.records
        responseTotalRecordsMatched = response.totalRecordsMatched
      } catch (error: any) {
        if (isCancel(error)) return
        responseError = error.response?.status
        Logger.error("No results with source.id filter active:", user.currentSources.toString())
      }

      // GET information from orders with target.source.id filter active
      // TODO: Make Multicliente solution, initial testings are only for PRIVILEGE
      // TODO: Paginator is not working in "all" searchs. Need to fix it with a service, can't be done in the frontend
      if (website === "privilege") {
        try {
          if (statusValue === EShippinggroupStatus.all.toString()) {
            responseError = null
            requestBody.filter = {
              ...ListValidshippingTypes,
              "target.source.id": user.currentSources,
            }
            response = await getOrderByStatus(statusValue, requestBody, newCancelToken())
            response = response.data.message
            responseTargetRecords = response.records
            responseTargetTotalRecordsMatched = response.totalRecordsMatched
          }
        } catch (error: any) {
          if (isCancel(error)) return
          if (!responseRecords.length) {
            responseError = error.response?.status
            Logger.error(
              "No results with target.source.id filter active:",
              user.currentSources.toString(),
            )
          }
        }
      }

      // If doesn't exist orders, return error message
      if (!!responseError) {
        if (responseError === TransactionCodeEnum.notFound) {
          setLoading(false)
          setOrdersGroup(null)
          return
        }
        errorHander?.dispatch({ hasError: true, code: responseError })
      }

      // Clean orders separated by source.id and target.source.id
      if (website === "privilege") {
        if (statusValue === EShippinggroupStatus.all.toString()) {
          responseRecords = responseRecords.filter(
            (_sg: any) => _sg.currentStatus.tag !== specialSourceStates[1],
          )

          responseTargetRecords = responseTargetRecords
          .filter((_sg: any) =>
            specialSourceStates.includes(_sg.currentStatus.tag),
          ).map((_sg: any) => {
            if(_sg.currentStatus.tag === "IN_TRANSIT") {
              _sg.currentStatus.frontTag = "IN_TRANSIT_SOURCE"
            }
            return _sg 
          })
        }
      }

      // Concat orders separated by source.id and target.source.id
      purgedOrders = responseRecords.concat(responseTargetRecords)

      // Calculate the total records to paginate
      const totalRecordsMatched =
        (responseTotalRecordsMatched + responseTargetTotalRecordsMatched) / configEnv.maxRecords

      // Order the orders by orderCreationDate
      orders = groupBy("orderCreationDateFormat", formatDateOrders(purgedOrders))
      setOrdersGroup(orders)
      setMaxPage(Math.ceil(totalRecordsMatched))
      setLoading(false)
    }

    getOrdersGroupByDate()

    // eslint-disable-next-line
  }, [status, page, rangeDate, search, sort, customFilters])

  /**
   * onSelectedRangeDate Constant where the range and page number of the listings is placed
   * @param range the type IRangeExtend
   * @return void
   */
  const onSelectedRangeDate = (range?: IRangeExtend) => {
    setRangeDate(range)
    setPage(INITIAL_PAGE)
  }

  const onChangeSortDate = (sort: SortOrderEnum) => {
    setSort(sort)
    setPage(INITIAL_PAGE)
  }

  const handleChangeCustomFilters = (filter: typeVariantObject | null) => {
    setCustomFilters(filter)
    setPage(INITIAL_PAGE)
  }
  /*

  Return the key that contain the status sent in the parameters

  @param {String} status - Order/SG status of OMNIX 
  @return {String} statusValue


  */

  // const validateStatusOrder = (status: string) => {
  //   const statusValue: EShippinggroupStatus =
  //     EShippinggroupStatus[status as keyof typeof EShippinggroupStatus]
  //   return statusValue
  // }

  /*

  Transform the date if date match yesterday or today to show this in the list

  @param {IOrderShippinggroup[]} orders - Array with all the SGS
  @return {IOrderShippinggroup[]} Array with the date transformed


  */

  const formatDateOrders = (orders: IOrderShippinggroup[]) => {
    const today = new Date()
    const yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)

    const todayFormat = formatDate(today)
    const yesterDayFormat = formatDate(yesterday)

    orders.forEach((order) => {
      let orderDate = formatDate(order.orderCreationDate)
      if (window.$website === "wom") {
        let indexAwaiting = order.status.findIndex(status => status.tag === "AWAITING_STORE_CONFIRMATION")
        if (!isNaN(indexAwaiting)) {
          let {date} = order.status[indexAwaiting]
          orderDate = formatDate(date)
        }
      }
      order.orderCreationDateFormat =
        orderDate === todayFormat ? TODAY : orderDate === yesterDayFormat ? YERTERDAY : orderDate
    })

    return orders
  }

  return {
    ordersGroup,
    loading,
    setPage,
    maxPage,
    page,
    onSelectedRangeDate,
    setSearch,
    onChangeSortDate,
    handleChangeCustomFilters,
  }
}

export default useGetOrderByStatus
