import React, { Suspense, useContext, useEffect } from "react"
import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import { OrderDetailType } from "@Types/OrderType"
import usePickupOrder from "@Pages/DetailOrderScreens/Pickup/UsePickupOrder"
import PickupAction from "@Components/OrderDetail/PickupProductOrder/PickupAction"
import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import { IconSpinner } from "@Theme/common/Icon.styled"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { clients } from "@Webapp-Clients/Clients"
import { AuthContext } from "@Context/context/AuthContext"
import useHideActionButtons from "@Hooks/UseHideActionButtons"

const InfoElocker = React.lazy(() => import("@Components/OrderDetail/ElockerQR/InfoElocker"))

const PickupScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  setFinishedShippingGroupAction,
  finishedShippingGroupAction,
  order,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const {hidePickupActions, updateVisibilityButtonActions} = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker
  const documents = shippinGroupDetail?.custom?.documents

  const {
    loadingCancel,
    loadingPickup,
    handleClickCancelAction,
    handleClickPickupAction,
    hasPermissionQREntry,
    hasPermissionQRExitCancel,
    canCancelOrder,
    denyPickupAction,
  } = usePickupOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
    isElocker: elockerInfo?.isElocker,
  })

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos por recoger" : undefined}
          />
          {elockerInfo && elockerInfo.isElocker && (
            <Suspense
              fallback={
                <>
                  <IconSpinner variant="big" className="content-center" />
                  <p className="padding-content">Cargando información Elocker</p>
                </>
              }
            >
              <InfoElocker
                codigoCancelacion={hasPermissionQRExitCancel ? elockerInfo.codigoCancelacion : null}
                codigoEntrega={null}
                codigoRetiro={hasPermissionQREntry ? elockerInfo.codigoRetiro : null}
                puerta={elockerInfo.puerta}
              />
            </Suspense>
          )}

          {!finishedShippingGroupAction && !hidePickupActions && (
            <PickupAction
              onClickPickup={!denyPickupAction ? handleClickPickupAction : undefined}
              onClickCancel={canCancelOrder ? handleClickCancelAction : undefined}
              loadingCancel={loadingCancel}
              loadingPickup={loadingPickup}
            />
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(PickupScreen)
