import { Suspense, useContext, useEffect } from "react"
import OrderDetailHOC from "@Hoc/OrderDetailHOC"
import SummaryProductList from "@Components/OrderDetail/SummaryProducts/SummaryProductsList"
import { OrderDetailType } from "@Types/OrderType"
import useDeliveryOrder from "@Pages/DetailOrderScreens/Delivery/UseDeliveryOrder"
import DeliveryAction from "@Components/OrderDetail/DeliveryProductOrder/DeliveryAction"
import SkeletonShippinGroupDetail from "@Components/Commons/Skeleton/SkeletonShippinGroupDetail"
import TagPrintContent from "@Components/OrderDetail/TagPrint/TagPrintContent"
import { AuthContext } from "@Context/context/AuthContext"
import { clients } from "@Webapp-Clients/Clients"
import useHideActionButtons from "@Hooks/UseHideActionButtons"

const DeliveryScreen = ({
  shippinGroupDetail,
  shippingGroupId,
  finishedShippingGroupAction,
  order,
  setFinishedShippingGroupAction,
}: OrderDetailType) => {
  const {
    state: { user },
  } = useContext(AuthContext)
  const {hideDeliveryActions, updateVisibilityButtonActions} = useHideActionButtons()

  useEffect(() => {
    updateVisibilityButtonActions(
      shippinGroupDetail?.source?.id,
      shippinGroupDetail?.target?.source?.id
    )
  }, [shippinGroupDetail, updateVisibilityButtonActions])

  const client = clients[user?.mainGroup.id as keyof typeof clients]
  const FilesList = client["FilesList" as keyof typeof client]

  const FormFileShippingGroup = client["FormFileShippingGroup" as keyof typeof client]

  const itemsOrder = shippinGroupDetail?.source.items
  const documents = shippinGroupDetail?.custom?.documents
  const elockerInfo = shippinGroupDetail?.custom?.infoElocker

  const { loadingDelivey, handleClickDeliveryOrder } = useDeliveryOrder({
    shippingGroupId,
    setFinishedShippingGroupAction,
  })

  return (
    <>
      {itemsOrder ? (
        <>
          <SummaryProductList
            itemsOrder={itemsOrder}
            groupPicking={false}
            titleMain={finishedShippingGroupAction ? "Productos despachados" : undefined}
          />
          {!finishedShippingGroupAction && !hideDeliveryActions && (
            <>
              {FormFileShippingGroup && (
                <Suspense fallback={null}>
                  <FormFileShippingGroup />
                </Suspense>
              )}
              <DeliveryAction
                loadingDelivery={loadingDelivey}
                onClickDeliveryOrder={handleClickDeliveryOrder}
              />
            </>
          )}
          {order && (
            <TagPrintContent
              codigoEntrega={(elockerInfo?.isElocker && elockerInfo?.codigoEntrega) || null}
              order={order}
              shippingGroupId={shippingGroupId}
            />
          )}
          {FilesList && (
            <Suspense fallback={null}>
              <FilesList
                documents={documents}
                channel={shippinGroupDetail?.channel}
                orderId={order?.id}
              />
            </Suspense>
          )}
        </>
      ) : (
        <SkeletonShippinGroupDetail />
      )}
    </>
  )
}

export default OrderDetailHOC(DeliveryScreen)
